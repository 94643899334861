import React from "react";
import {Helmet} from 'react-helmet'
import '../../application/components/thankYou.css'



export default function ThankYou() {

    return (
        <>
		
			<Helmet>
				<title> Uplyft Capital Onboarding Merchant | Thank-You </title>
			</Helmet>
            <main className="ty__inner" style ={{minHight: '500px', padding: '200px 0 100px 0'}}>
           	 	<div className="ty">
					<div className="ty__inner">
						<div className="ty__left">
                            <div className="ty__content"><h1>Thank you!</h1>
                                <p>Thank you for your interest in our syndication program!&nbsp;</p>
                                <p>We are processing your inquiry and will call you shortly to say hello.&nbsp;</p>
                            </div>
							<div className="ty__footer-text">
                                <h2>Have questions?</h2>
                                <p>Feel free to call us, we’ll be happy to assist you. <a href="tel:1 800 515-7531"><strong>1 (800) 515-7531</strong></a></p>
							</div>
							<div className="ty__buttons">
								<a href="https://uplyftcapital.com" className="btn btn-primary ">Back to Site</a>
							</div>
						</div>
						<div className="ty__right js-is-seen">
							<lottie-player
								src="https://assets3.lottiefiles.com/packages/lf20_zZqlvq.json" background="transparent"  speed="1"  style={{ width: '500px', height: 'auto', transform:'scale(1.4)', marginTop:'-95px' }} autoplay >
							</lottie-player>
						</div>
					</div>
				</div>
            </main>
        </>

    );
}
