import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import './thankYou.css'

export default function ThankYou() {
	let history = useHistory();

	useEffect(() => {
		sessionStorage.clear();
		history.entries = [];
		history.index = -1;
		history.push('/onboarding/merchant/finished');
	}, []);

	return (
		<>
			<Helmet>
				<title> Uplyft Capital Onboarding Merchant | Thank-You </title>
			</Helmet>
			<main className="page__inner mt-5" role="main">
				<div className="ty">
					<div className="ty__inner">
						<div className="ty__left">
							<div className="ty__content"><h1>Thank you!</h1>
								{/* <p>Our sales team is processing your application and will call you shortly to say hello. If you haven&#8217;t already uploaded them, please prepare the 3 most recent business account statements and email them to <a href="mailto:sales@uplyftcapital.com">sales@uplyftcapital.com</a>, or fax them to <a href="tel:305 999-5312">305-999-5312.</a></p> */}
								<p>Great Work! Your submission has been received.

									If you haven't already, please ensure your Experian credit is unlocked for the next 24-48 hrs. This is a soft inquiry for verification purposes and will not affect your credit score.

									Thanks again for working with Uplyft Capital</p>
							</div>
							<div className="ty__footer-text">
								<h2>Have questions?</h2>
								<p>Feel free to call us, we’ll be happy to assist you. <a href="tel:1 800 515-7531"><strong>1 (800) 515-7531</strong></a></p>
							</div>
							<div className="ty__buttons">
								<a href="https://uplyftcapital.com" className="btn btn-primary ">Back to Site</a>
							</div>
						</div>
						<div className="ty__right js-is-seen">
							<lottie-player
								src="https://assets3.lottiefiles.com/packages/lf20_zZqlvq.json" background="transparent" speed="1" style={{ width: '500px', height: 'auto', transform: 'scale(1.4)', marginTop: '-95px' }} autoplay >
							</lottie-player>
						</div>
					</div>
				</div>
			</main>
		</>
	);
}
